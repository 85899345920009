@media screen and (max-width: 550px) {
    div#forget-passw-container {
        width: 70%;
        height: 303px;
        background: white;
        box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.12);
        border-radius: 16px;
        padding: 25px;

        h1.title {
            font-size: 20px;
        }
    }
}