div#login-container {
    width: 448px;
    min-height: 382px;
    background: white;
    box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 30px;
}

h1.title{
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 10px;
    margin-top: 20px;
}

div.form-group {
    width: 100%;
    min-height: 70px;
    display: flex;
    margin-bottom: 16px;
    margin-top: 16px;
    flex-direction: column;
    justify-content: center;

    label {
        font-size: 14px;
        font-weight: 500;
        color: black;
        margin-bottom: 7px;
    }

    input {
        width: 100%;
        height: 40px;
        background-color: white;
        border: 1px solid #D8D8D8;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        padding-left: 10px;
        outline: rgba(0, 0, 0, 0.3);
    }
}

button.btn-login {
    width: 100%;
    height: 40px;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 900;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin-bottom: 16px;
}

a.forget-password{
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: black;
    text-decoration: none;
}

@import './LoginMobileScreen.scss'