@media screen and (max-width:550px) {
    div#login-container {
        width: 70%;
        height: 350px;
        background: white;
        box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.12);
        border-radius: 16px;
        padding: 25px;
    }

    h1.title {
        margin-bottom: 30px !important;
        margin-top: 30px !important;
    }
}