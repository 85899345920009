.bar1,
.bar2,
.bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

.change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
}

.login-input {
    height: 43px;
    flex-shrink: 0;
    border-radius: 8px;
    outline: none;
    font-size: 16px;
    border: 1.5px solid var(--Background, #F4F4F4);
    background: var(--Background, #F4F4F4);
    padding-left: 12px;
    box-shadow: none !important;
}