#sidebar{
    height: 100%;
    background-image: linear-gradient(0deg, rgba(248, 28, 163, 0.1) 0%, rgba(125, 32, 210, 0.1) 49.31%, rgba(0, 117, 243, 0.1) 100%);
    // opacity: 0.1;
    mix-blend-mode: normal;
}

div.sidebar-link-container{
    // display: grid;
    // grid-template-columns: 45px 1fr;
    display: flex;
    align-items: center;
    margin: auto;
    margin-top: 26px;
    width: 216px;
    // background: #FFFFFF;
        // border: 1px solid #DFDFDF;
        // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        height: 40px;
}

div.sidebar-link {
    font-family: 'Saathi-UltraBold';
    font-size: 20px;
}

@media screen and (max-width: 1024px) {
    #sidebar{
        background-color: white;
        background-image: url('../../assets/images/sidebar-bg.svg');
        background-position: center;
        background-size: cover;
    }
}