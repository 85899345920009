#AuthenticationPage{
    min-height: 100vh;
}

div.radialPinkBlueGradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -2;
    background: radial-gradient(rgba(251, 164, 218, 1) 0%,
            rgba(153, 199, 251, 1) 100%);
}

div.whiteningLinearGradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    background: linear-gradient(180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0.5) 100%);
}

div.topGradientContainer{
    position: relative;
    overflow: hidden;
    height: 100vh;
}

div.login-section{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}

div.logo-container{
    width: 528px;
    margin-bottom: 20px;
    margin-left: 70px;
}

@import './AuthenticationPageMobileScreen.scss'